import { useFormContext } from 'react-hook-form';
import FormContainer from '../../../components/layout/FormContainer';
import { Box } from '@mui/material';
import SelectApplications from '../../../components/common/SelectApplications';
import Select from '../../../components/common/Select';
import { TIKTOK_ADS_AD_LIST } from '../../../constants';
import { AdAccountSelect } from './TikTokFormComponents';
import { useCallback } from 'react';

const AdSettings = () => {
  const { setValue, trigger } = useFormContext();

  const listMapper = ({ name, code }) => ({
    label: name,
    value: code,
  });

  const handleChangeApp = useCallback(() => {
    setValue('selectedCampaigns', []);
    setValue('selectedCreatives', []);
    setValue('adAccounts', '');
    void trigger('adAccounts');
  }, [trigger, setValue]);

  const handleChangeAccId = useCallback(() => {
    setValue('selectedCampaigns', []);
    setValue('selectedCreatives', []);
  }, [trigger, setValue]);

  return (
    <FormContainer title={'Choose App and Ad Type'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'start', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <SelectApplications name={'app'} handleChange={handleChangeApp} />
          <Select
            name="adType"
            inputLabel="Ad Type"
            options={TIKTOK_ADS_AD_LIST.map(listMapper)}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <Box sx={{ flex: 1 }}><AdAccountSelect handleChange={handleChangeAccId} /></Box>
          <Box sx={{ flex: 1 }}></Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

export default AdSettings;