import { useMe } from '../api/getMe';

const useCheckAuth = () => {
  const { data, isLoading, isSuccess } = useMe();
  return {
    username: data?.username,
    email: data?.email,
    isAuthenticated: data && data?.auth,
    isLoading,
    isSuccess,
  };
};

export default useCheckAuth;