import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';

interface ResponseMe {
  auth: boolean;
  email: string;
  username: string;
}

export const getMe = async (signal?: AbortSignal): Promise<ResponseMe> => {
  const res = await apiClient.get<ResponseMe>(`/api/v1/user/me`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data;
};

interface UseUsersListOptions {
  config?: SynchronousQueryConfig<typeof getMe, ResponseMe>;
}

export const useMe = ({ config }: UseUsersListOptions = {}) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getMe(signal),
    queryKey: ['api', 'v1', 'user', 'me'] as QueryKey,
  });
};
