import { Suspense, useCallback, useEffect, useMemo } from 'react';
import useStepper from '../../../hooks/useStepper';
import type { StepType } from '../../../components/layout/StepperPaper';
import StepperPaper from '../../../components/layout/StepperPaper';
import AppPlatformForm from './AppPlatformForm';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { facebookSchema } from '../validation/facebook.schema';
import FormNavigation from '../../../components/layout/FormNavigation';
import AdAccountsForm from './AdAccountsForm';
import CampaignsForm from './CampaignsForm';
import AdSetsForm from './AdSetsForm';
import CreativesForm from './CreativesForm';
import AdForm from './AdForm';
import useJobStatusCheck from '../../../hooks/useJobStatusCheck';
import type { CreateFacebookAdResponse } from '../api/createFacebookAd';
import { useCreateFacebookAd } from '../api/createFacebookAd';
import queryKeys from '../api/queryKeys';
import queryKeysCreatives from '../../creatives/api/queryKeys';
import CircularProgress from '../../../components/common/CircularProgress';
import { AddPackButton, JobResultTable } from './FacebookFormComponents';
import { queryClient } from '../../../lib/react-query';
import type { CreativePayloadEntry } from '../api/types';
import useEnqueueSnackbar from '../../../hooks/useEnqueueSnackbar';
import type { CreativeEntry } from '../../../types/shared';
import type { AdSetEntry } from '../api/getAdSets';
import { uniqBy } from 'lodash-es';
import { useIsFetching } from '@tanstack/react-query';

interface FormData {
  app?: string;
  platform?: string;
  team?: string;
  adType?: string;
  adAccounts?: string[];
  selectedCampaigns?: string[];
  selectedAdSets?: string[];
  selectedCreatives?: string[];
  callToActions?: string;
  selectedConversionDomain?: string;
  selectedAdSettings?: Record<string, unknown>;
  hotjar?: boolean;
  activeCampaigns?: boolean;
  conversionDomain?: string;
  adSettings?: string;
  adLabelSuffix?: string;
  packsWithCreatives?: string[][];
  activePacksWithCreatives?: number | null;
  filterCampaignsByName: string;
  filterAdSetsByName: string;
}


const FacebookForm = () => {
  const { onShowErrorAlert } = useEnqueueSnackbar();

  const initialSteps = useMemo<StepType[]>(
    () => [
      { title: 'App & Platform', component: AppPlatformForm },
      { title: 'Ad Accounts', component: AdAccountsForm },
      { title: 'Campaigns', component: CampaignsForm },
      { title: 'Ad Sets', component: AdSetsForm },
      { title: 'Creatives', component: CreativesForm },
      { title: 'Ad', component: AdForm },
    ],
    [],
  );
  const {
    currentStep,
    resetSteps,
    prevStep,
    nextStep,
    StepComponent,
  } = useStepper({ initialSteps, defaultStep: 0 });

  useEffect(() => {
    // Refresh Campaigns
    if (currentStep === 2) {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.campaigns,
      });
    }

    // Refresh AdSets
    if (currentStep === 3) {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.adSets,
      });
    }

    // Refresh Creatives
    if (currentStep === 4) {
      void queryClient.invalidateQueries({
        queryKey: queryKeysCreatives.allCreatives,
      });
    }
  }, [currentStep]);

  const adSettingsIsFetching = useIsFetching({
    queryKey: queryKeys.adSettings,
  });

  const getDefaultValues = useMemo(() => {
    return {
      app: 'YG',
      platform: 'WEB',
      team: 'UA',
      marketingProcess: '',
      adType: '',
      adAccounts: [],
      selectedCampaigns: [],
      selectedAdSets: [],
      selectedCreatives: [],
      packsWithCreatives: [],
      activePacksWithCreatives: null,
      selectedAdSettings: {},
      selectedConversionDomain: '',
      adLabelSuffix: '',
      callToActions: 'LEARN_MORE',
      hotjar: false,
      activeCampaigns: false,
      filterCampaignsByName: '',
      filterAdSetsByName: '',
    };
  }, []);

  const formMethods = useForm<FormData>({
    resolver: yupResolver(facebookSchema),
    defaultValues: getDefaultValues,
    context: { currentStep },
  });

  const {
    trigger,
    reset: resetForm,
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitted },
  } = formMethods;

  const {
    data: jobStatusResponse,
    runJubStatusCheck,
    isLoading: isJobStatusChecking,
    isFinished: isJobStatusFinished,
    clearJobStatus,
  } = useJobStatusCheck();

  const createFacebookAd = useCreateFacebookAd({
    config: {
      onSuccess: (r: CreateFacebookAdResponse) => {
        runJubStatusCheck(r.job_id);
      },
      onError: (err) => {
        if (err.cause) {
          onShowErrorAlert((err.cause as { details?: string })?.details ?? 'Error occur', { delayAlert: 3500 });
          console.error(err.cause);
        }
      },
    },
  });

  const onSubmit = useCallback((data: FormData) => {
    if (!isValid) return;

    const getCreatives = (): CreativePayloadEntry[] | CreativePayloadEntry[][] => {
      const cachedCreativesData =
        queryClient
          .getQueryCache()
          .findAll({ queryKey: queryKeysCreatives.allCreatives })
          .flatMap(query => query.state.data as CreativeEntry[])
          .filter(c => !!c);

      if (!cachedCreativesData) return []; // in case when creatives has not been received

      // Generate creative item
      const _getFields = (currentPack: CreativePayloadEntry) => ({
        id: currentPack.id,
        creative_id: currentPack.creative_id,
        name: currentPack.name,
        creative_type: currentPack.creative_type,
      });

      if (data.packsWithCreatives?.length) {
        return data.packsWithCreatives.map((pack: string[]) => pack.map(packId => {
          const currentPack = cachedCreativesData.find((c) => c.id === packId) as CreativePayloadEntry;
          return _getFields(currentPack);
        }));
      }

      return data.selectedCreatives ? data.selectedCreatives.map((id: string) => {
        const currentPack = cachedCreativesData.find((c) => c.id === id) as CreativePayloadEntry;
        return _getFields(currentPack);
      }) : [];
    };

    const getAdSets = () => {
      const cachedAdSetsData = queryClient
        .getQueryCache()
        .findAll({ queryKey: queryKeys.adSets })
        .flatMap((query) => query.state.data as AdSetEntry[])
        .filter(c => !!c);

      return data.selectedAdSets
        ? uniqBy(cachedAdSetsData, 'id').filter((c) => data.selectedAdSets?.includes(c.id))
        : null;
    };

    createFacebookAd.mutate({
      app: data.app,
      platform: data.platform,
      team: data.team,
      ad_type: data.adType,
      call_to_action: data.callToActions,
      creatives: getCreatives(),
      ad_sets: getAdSets(),
      hotjar: data.hotjar,
      active_campaigns: data.activeCampaigns,
      conversion_domain: data.selectedConversionDomain,
      ad_settings: data.selectedAdSettings,
      optional_ad_label: data.adLabelSuffix,
    });
  }, [isValid, createFacebookAd]);

  const handleNextStep = useCallback(async () => {
    await trigger(); // Manually triggers form validation.
    if (!isValid || isSubmitting || isSubmitted) return;
    if (currentStep === initialSteps.length - 1) {
      void handleSubmit(onSubmit)();
    } else {
      nextStep();
    }
  }, [
    nextStep,
    trigger,
    handleSubmit,
    onSubmit,
    initialSteps,
    currentStep,
    isValid,
    isSubmitting,
    isSubmitted,
  ]);

  const handlePrevStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const handleRestart = useCallback(() => {
    resetForm();
    resetSteps();
    clearJobStatus();
  }, [resetForm, resetSteps, clearJobStatus]);


  const isDisabledNext = !!adSettingsIsFetching || (isSubmitted ?? isJobStatusChecking);

  return (
    <StepperPaper title={'Facebook Ads Automation'} steps={initialSteps} activeStep={currentStep}>
      <FormProvider {...formMethods}>
        {
          isJobStatusChecking ? (
            <CircularProgress title={'Job status is checking...'} />
          ) : (
            <Suspense fallback={<CircularProgress />}>
              {isJobStatusFinished ? (
                <JobResultTable rows={jobStatusResponse?.result} />
              ) : (
                <StepComponent />
              )}
            </Suspense>
          )
        }

        <FormNavigation
          isJobStatusFinished={isJobStatusFinished}
          onRestart={handleRestart}
          isDisabledRestart={!currentStep}

          onBack={handlePrevStep}
          isDisabledBack={!currentStep}

          onNext={handleNextStep}
          isDisabledNext={isDisabledNext}
          titleNext={currentStep === initialSteps.length - 1 ? 'Create Ad' : 'Next'}
          childrenOnTheRightSide={currentStep === 4 ? <AddPackButton /> : null}
        />
      </FormProvider>
    </StepperPaper>
  );
};
export default FacebookForm;