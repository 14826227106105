import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress from '../../../components/common/CircularProgress';
import useRowsAdSets from '../hooks/useRowsAdSets';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';
import TextField from '../../../components/common/TextField';
import type { GridRowsProp } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';

interface AdSet {
  id: string;
  name: string;
  ads: number;
  status: string;
  disabled?: boolean;
}

const COLUMNS = [
  { field: 'name', headerName: 'Adset Name', width: 850 },
  { field: 'ads', headerName: '№ Ads' },
  { field: 'status', headerName: 'Status' },
];

const AdSetsForm = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const selectedAdSets = watch('selectedAdSets') as string[];
  const isAdSetsFieldError = errors.selectedAdSets?.message;
  const filterByName = watch('filterAdSetsByName') as string;

  const { rows, isLoading, isPending, isError, error } = useRowsAdSets();

  const filteredRows = useMemo(() =>
    rows?.filter(r =>
      !filterByName ||
      r.name.toLowerCase().includes(filterByName.toLowerCase()),
    ), [rows, filterByName]);

  const handleSelectionMode = useCallback(
    (rows: GridRowSelectionModel) => {
      setValue('selectedAdSets', rows);
    },
    [setValue],
  );

  useEffect(() => {
    void trigger('selectedAdSets');
  }, [trigger, selectedAdSets]);

  if (isPending) {
    return <CircularProgress title={'Ad Sets are loading...'} />;
  }

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Ad Sets'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching ad sets'} />
    );
  }

  if (!rows?.length) {
    return <Typography>No ad sets found.</Typography>;
  }

  return (
    <FormContainer
      title={'Choose Ad Sets'}
      error={isAdSetsFieldError as string}
      sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
      {!isPending && rows?.length && <>
        <TextField placeholder={'Search by name'} name={'filterAdSetsByName'} />
        <Box style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={filteredRows as GridRowsProp<AdSet>}
            columns={COLUMNS}
            checkboxSelection
            rowSelectionModel={selectedAdSets}
            loading={isLoading}
            onRowSelectionModelChange={handleSelectionMode}
            hideFooterPagination
            getRowId={(row) => row.id}
            isRowSelectable={(params) => !params.row.disabled}
            getRowClassName={(params) => params.row.disabled ? 'row-disabled' : ''}
            sx={t => ({ '.row-disabled': { color: t.palette.action.disabled } })}
          />
        </Box>
      </>}
    </FormContainer>

  );
};

export default AdSetsForm;