import * as yup from 'yup';
import { CPA_VALUE, ROAS_VALUE } from '../../../constants';

export const tikTokSchema = yup.object().shape({
  app: yup.string().required('Can\'t be empty'),
  adAccounts: yup.string().when('app', {
    is: (val) => !val,
    then: (schema) => schema.required('Application should be chosen first'),
    otherwise: (schema) => schema.required('Can\'t be empty'),
  }),
  adType: yup.string().required('Can\'t be empty'),
  selectedCampaigns: yup.array().when('$currentStep', {
    is: (val) => val === 1,
    then: (schema) =>
      schema.min(1, 'At least one campaign has to be selected').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedCreatives: yup.array().when('$currentStep', {
    is: (val) => val === 2,
    then: (schema) =>
      schema.min(1, 'At least one creative has to be selected').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  filterCreativeByName: yup.string(),
  textId: yup.number(),
  team: yup.string(),
  bidStrategy: yup.string().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  targetCPA: yup.string().when('bidStrategy', {
    is: (val) => val === CPA_VALUE,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  targetROAS: yup.string().when('bidStrategy', {
    is: (val) => val === ROAS_VALUE,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  addGroupNumber: yup.string().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  flow: yup.string().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  budget: yup.string().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  spendingPower: yup.string(),
  age: yup.array().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) =>
      schema.min(1, 'At least one has to be applied').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  gender: yup.array().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) =>
      schema.min(1, 'At least one has to be applied').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
});
