import { useSuspenseQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { apiClient } from '../../../lib/api/apiClient';
import queryKeys from './queryKeys';

export const getFlows = async ({ params }, signal) => {
  const res = await apiClient.get(`/api/v1/tiktok_ads_manager/get_flows`, {
    params, // {a: 1, b: 2}
    signal,
    paramsSerializer: (prms) =>
      queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

export const useFlows = ({ params, config }) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getFlows({ params }, signal),
    queryKey: [
      ...queryKeys.flows,
      Object.values(params).join(','),
    ],
  });
};
