import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import Select from '../../../components/common/Select';
import { BID_STRATEGIES_LIST, DEFAULT_TARGET_CPA, DEFAULT_TARGET_ROAS } from '../../../constants';
import { useAccounts } from '../api/getAccounts';
import { useTexts } from '../api/getTexts';
import { useFlows } from '../api/getFlows';

export const AdAccountSelect = ({ handleChange }) => {
  const { watch } = useFormContext();
  const app = watch('app');

  const { data, isLoading } = useAccounts({
    params: { app, platform: 'WEB' },
    config: {
      enabled: !!app,
      select: (data) =>
        data.map((item) => ({
          value: item.account_id,
          label: item.name,
        })),
    },
  });
  return isLoading ? (
    <Skeleton
      height={40}
      sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
    />
  ) : (
    <Select
      name="adAccounts"
      inputLabel="Ad Account"
      disabled={isEmpty(data)}
      options={data ?? []}
      handleChange={handleChange}
    />
  );
};

export const FlowsSelect = () => {
  const { watch } = useFormContext();
  const app = watch('app');

  const { data } = useFlows({
    params: { app },
    config: {
      select: (data) =>
        data.map((item) => ({
          value: item.name,
          label: item.name,
        })),
    },
  });
  return (
    <Select
      name="flow"
      inputLabel="Flow"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const BidStrategySelect = () => {
  const { setValue } = useFormContext();

  const bidStrategies = BID_STRATEGIES_LIST.map(({ value, name }) => ({
    value,
    label: name,
  }));

  return (
    <Select
      name="bidStrategy"
      inputLabel="Bid Strategy"
      options={bidStrategies}
      handleChange={() => {
        setValue('targetROAS', DEFAULT_TARGET_ROAS);
        setValue('targetCPA', DEFAULT_TARGET_CPA);
      }}
    />
  );
};

export const TextsSelect = () => {
  const { watch } = useFormContext();
  const app = watch('app');
  const adType = watch('adType');

  const { data } = useTexts({
    params: { app },
    config: {
      enabled: adType === 'SINGLE_VIDEO',
      select: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.text,
        })),
    },
  });

  return adType === 'SINGLE_VIDEO' ? (
    <Select
      name="textId"
      inputLabel="Ad Text"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  ) : null;
};

export const JobResultTable = ({ rows }) => {
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 220 }}>Account Ad ID</TableCell>
            <TableCell align="center" sx={{ minWidth: 120 }}>
              AdGroup
            </TableCell>
            <TableCell align="center">Creative</TableCell>
            <TableCell align="center" sx={{ minWidth: 120 }}>
              Result
            </TableCell>
            <TableCell align="center" sx={{ minWidth: 120 }}>
              Ad ID
            </TableCell>
            <TableCell align="center">Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={`${i}:${row.name}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.advertiser_id}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {' '}
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.adgroup_name}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.creative_name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {row.is_successful ? '✅' : '❌'}
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.ad_id}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  sx={{
                    fontSize: 14,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.message}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
